<template>
  <div>
    <AppMenu />
    <PatologiesSection />

    <CtaContactSection />
    <FooterSection />

    <!-- <Banner /> -->
    <!-- <Contacts /> -->
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import PatologiesSection from "../components/PatologiesSection.vue";

import CtaContactSection from "../components/CtaContactSection.vue";
// import Banner from "../components/Banner.vue";
import FooterSection from "../components/FooterSection.vue";

export default {
  name: "Patologies",
  components: {
    AppMenu,
    PatologiesSection,
    CtaContactSection,
    FooterSection,
    // Banner,
  },
};
</script>
