<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white">
    <div
      class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"
    >
      <div>
        <h2
          class="text-base font-semibold tracking-wide text-blue-600 uppercase"
        >
          Le patologie trattate
        </h2>
        <p class="mt-2 text-3xl font-extrabold text-gray-900">
          Affidati ai professionisti
        </p>
        <p class="mt-4 text-lg text-gray-500">
          Il Centro CERCA e riCERCA è specializzato nella diagnosi e la cura di
          un ampio spettro di patologie maligne e benigne a carico delle alte
          vie digestive.
        </p>
      </div>
      <div class="mt-12 lg:mt-0 lg:col-span-2">
        <dl
          class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8"
        >
          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>

            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="acalasia">
                  Acalasia esofagea
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="reflusso">
                  Reflusso gastroesofageo
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="barrett">
                  Esofago di Barrett
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="jatale">
                  Ernia jatale permagna
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="diverticoli">
                  Diverticoli esofagei
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="zenker">
                  Diverticolo di Zenker
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="esofago">
                  Tumori dell’esofago
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="leiomioma">
                  Leiomioma
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="gist">
                  GIST
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
              />
            </svg>
            <div class="ml-3">
              <dt
                class="text-lg font-medium leading-6 text-gray-900 hover:text-blue-500"
              >
                <router-link to="stomaco">
                  Tumori dello stomaco
                </router-link>
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatologiesSection",
};
</script>
